import type { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { cardBackround } from '@/styles/shared';

export const Wrapper = styled.div`
  display: none;

  @media ${({ theme }) => theme.media.desktop} {
    display: block;
  }

  ${cardBackround};
  width: 100%;
  padding: 15px;
  position: relative;

  h2 {
    font-size: 24px;
  }

  button {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    position: absolute;
    color: ${({ theme }) => theme.palette.dark};
    background: white;
    padding: 0;

    span {
      opacity: 0;
      font-size: 0;
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      width: 45px;
      height: 45px;
      top: 45%;
      z-index: 2;
      background: black;
      color: white;
    }

    &:nth-of-type(1) {
      left: 10px;
      transform: translateY(-50%);
    }

    &:nth-of-type(2) {
      right: 10px;
      transform: translateY(-50%) rotate(-180deg);
    }

    &:nth-of-type(3) {
      background: grey;
      bottom: 0;
      color: black;
    }
  }
`;

export const SlideWrapper = styled.div<{
  children: ReactNode;
}>`
  min-width: calc(100vw - 40px);
  max-width: 100vw;
  padding: 15px;
  position: relative;

  @media ${({ theme }) => theme.media.tablet} {
    min-width: 360px;
  }
`;

export const OverlayLink = styled.a<{
  'aria-label': string;
  href: string;
  rel: string;
  target: string;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  cursor: pointer;
`;

export const OverlayDiv = styled.div<{
  onClick: () => void;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  cursor: pointer;
`;

export const DotsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Dot = styled.div<{
  isActive: boolean;
  onClick: () => void;
}>`
  width: 11px;
  height: 11px;
  border-radius: 100%;
  background: ${({ theme }) => theme.palette.lightGray};
  margin: 0 3px;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${({ theme }) => theme.palette.primary};
    `}
`;
