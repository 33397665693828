import type { ReactNode } from 'react';
import styled from 'styled-components';

import { cardBackround } from '@/styles/shared';

export const CardWrapper = styled.div`
  display: none;

  @media ${({ theme }) => theme.media.desktop} {
    display: block;
  }

  ${cardBackround};

  h2 {
    font-size: 24px;
  }

  max-width: 400px;
  padding: 20px;
`;

export const SingleArticleWrapper = styled.div<{
  children: ReactNode;
}>`
  padding: 10px 0;
`;
