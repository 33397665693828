import { useSeoHead } from '@atoms/SeoHead/SeoHead.hook';
import Head from 'next/head';
import type { ReactElement } from 'react';

import type { SeoHeadProps } from './SeoHead.types';

export const SeoHead = ({
  title,
  metaDescription,
  blogSchema,
  breadcrumbSchema,
  image,
}: SeoHeadProps): ReactElement => {
  const {
    createBreadcrumbListSchema,
    createBlogPostSchema,
    createGeneralSchema,
    description,
    currentUrl,
    robots,
  } = useSeoHead({
    title,
    metaDescription,
    blogSchema,
    breadcrumbSchema,
  });

  const imageUrl = image?.startsWith('/')
    ? `${process.env.NEXT_PUBLIC_URL}${image}`
    : image;

  return (
    <Head>
      {blogSchema && (
        <script
          type="application/ld+json"
          id="blogPostingSchema"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(createBlogPostSchema(blogSchema)),
          }}
        />
      )}
      {breadcrumbSchema && (
        <script
          type="application/ld+json"
          id="breadcrumbSeoSchema"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(
              createBreadcrumbListSchema(breadcrumbSchema),
            ),
          }}
        />
      )}
      <script
        type="application/ld+json"
        id="blogSchema"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(createGeneralSchema()),
        }}
      />
      <title>{`${title} | Fox Paradox`}</title>
      <meta name="description" content={description} />
      <link rel="canonical" key="canonical" href={currentUrl} />
      <meta property="og:url" content={currentUrl} />
      <meta name="robots" key="robots" content={robots} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:locale" content="pl_PL" />
      {image && <meta property="og:image" content={imageUrl} />}
      <meta property="og:site_name" content="Fox Paradox" />
    </Head>
  );
};
